<template>
  <a-modal
    title="关联主管"
    style="top: 8px"
    width="40%"
    v-model="visible"
    :maskClosable="false"
    @ok="toSubmit"
    okText="提交"
    @cancel="toCancel"
    :confirm-loading="confirmLoading"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-model-item label="关联主管" prop="managerId">
        <a-select placeholder="请选择" v-model="rowData.managerId">
          <a-select-option :value="item.id" v-for="item in userList" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { resetPwd } from '@/api/system'
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      rowData: {},
      userList: [],
      // 表单验证
      formRule: {
        managerId: [{ required: true, message: '请选择主管', trigger: 'blur' }],
      },
    }
  },
  methods: {
    isShow(row) {
      this.visible = true
      this.axios.get(`/api/base/system/manager/listByDeptId/${row.id}`).then((res) => {
        this.userList = res.body || []
        //   console.log("666",this.userList)
      })
    },
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.confirmLoading = true
        let deptlist = this.userList.filter((item) => {
          return item.id === this.rowData.managerId
        })
        this.rowData.deptId = deptlist[0].deptId
        this.rowData.type = 0
        //   console.log(this.rowData)
        this.axios.post('/api/base/system/department/setLeaderId', this.rowData).then((res) => {
          if (res.code == 200) {
            this.visible = false
            this.rowData = {}
            this.$message.success(res.message)
            this.confirmLoading = false
            this.$emit('onload')
          }
        }).catch(err=>{
            this.confirmLoading = false
        })
      })
    },
    toCancel() {
        this.confirmLoading = false
        this.visible = false
        this.$refs.form.resetFields()
    },
  },
}
</script>