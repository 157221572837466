<template>
  <a-modal
    title="操作"
    style="top: 20px;"
    :width="800"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    :maskClosable="false"
  >
    <a-form :form="form">
      <a-form-item style="display:none">
        <a-input v-decorator="['id']"/>
      </a-form-item>

      <a-form-item
        :labelCol="
          labelCol"
        :wrapperCol="wrapperCol"
        label="上级部门"
      >
      <a-tree-select
        v-decorator="['parentId', {initialValue:'0',rules: [{ required: true, message: '请选择上级部门' }]}]"
        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
        :treeData="depts"
        placeholder="上级部门"
        treeDefaultExpandAll
      >
        </a-tree-select>
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="部门名称"
      >
        <a-input
          v-decorator="['name',{rules: [{ required: true, message: '请输入部门名称' }]}]"
          placeholder="部门名称"/>
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="部门编号"
      >
        <a-input
          v-decorator="['code',{rules: [{ required: true, message: '请输入部门编号' }]}]"
          placeholder="部门编号"/>
      </a-form-item>

<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="显示顺序"-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="['sort',{rules: [{ required: true, message: '请输入显示顺序' }]}]"-->
<!--          placeholder="显示顺序"/>-->
<!--      </a-form-item>-->

<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="负责人"-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="['leader',{rules: [{ required: true, message: '请输入负责人' }]}]"-->
<!--          placeholder="负责人"/>-->
<!--      </a-form-item>-->

<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="电话"-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="['phone',{rules: [{ required: true, message: '请输入电话' }]}]"-->
<!--          placeholder="电话"/>-->
<!--      </a-form-item>-->

<!--      <a-form-item-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="邮箱"-->
<!--      >-->
<!--        <a-input-->
<!--          v-decorator="['email',{rules: [{ required: true, message: '请输入邮箱' }]}]"-->
<!--          placeholder="邮箱"/>-->
<!--      </a-form-item>-->

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="状态"
      >
        <a-radio-group v-decorator="['flagEnable', {rules: [{ required: true, message: '请选择状态' }]}]">
          <a-radio :value="true">正常</a-radio>
          <a-radio :value="false">停用</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-input
          v-decorator="['remark',{rules: [{ required: false, message: '请输入备注信息' }]}]"
          placeholder="备注"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>
<script>
import { getDeptList, saveDept } from '@/api/system'
import pick from 'lodash.pick'
export default {
  name: 'DeptModal',
  components: {
  },
  data () {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      depts: [{ key: '0', value: '0', title: '无' }],
      mdl: {},
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },
  created () {

  },
  methods: {
    getParentList() {
      getDeptList().then(res => {
        this.buildtree(res.body.records, this.depts, 0)
      })
    },
    add (parentId) {
      this.form.resetFields()
      this.depts = [{ key: '0', value: '0', title: '无' }]
      this.edit({ parentId: parentId || 0 })
    },
    edit (record) {
      this.form.resetFields()
      this.depts = [{ key: '0', value: '0', title: '无' }]
      this.mdl = Object.assign({}, record)
      this.visible = true
      this.$nextTick(() => {
        this.mdl.parentId += ''
        this.form.setFieldsValue(pick(this.mdl, 'id', 'parentId', 'leader', 'phone', 'flagEnable', 'email', 'sort', 'name','code','remark'))
        // this.form.setFieldsValue({ ...record })
      })
      this.getParentList()
    },
    buildtree (list, arr, parentId) {
      list.forEach(item => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id + '',
            title: item.name,
            children: []
          }
          this.buildtree(list, child.children, item.id)
          arr.push(child)
        }
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          this.$emit('ok')
          this.confirmLoading = true
          saveDept(values).then(res => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.$emit('ok')
              this.visible = false
            } else {
              this.$message.error(res.message)
            }
          }).catch(() => {
            //this.$message.error('系统错误，请稍后再试')
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      })
    }
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  }
}
</script>
