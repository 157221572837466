<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{xs:6}" :wrapper-col="{xs:18}">
        <a-row :gutter="8">
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="部门" >
              <a-input placeholder="请输入" v-model="queryParam.name" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="编号" >
              <a-input placeholder="请输入" v-model="queryParam.code" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-model="queryParam.flagEnable" allowClear>
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="true">正常</a-select-option>
                <a-select-option value="false">停用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="6" :xl="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="this.fetch">查询</a-button>
              <a-button style="margin-left: 8px" @click="reset()">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div >
      <a-button v-if="addEnable" type="primary" icon="plus" @click="$refs.modal.add()">新增部门</a-button>
        <a-button type="primary" v-if="editEnabel" @click="toHandler('edit')">修改</a-button>
        <a-button type="primary" v-if="linkEnable" @click="toHandler('link')">关联主管</a-button>
        <a-button type="default" v-if="removeEnable" @click="toHandler('del')">删除</a-button>

    </div>
    <a-table
      ref="table"
      rowKey="id"
      :pagination="pagination"
      :loading="loading"
      :columns="columns"
      :dataSource="data"
      :rowClassName="rowClassName"
      :row-selection="rowSelection"
      >

      <span slot="menuType" slot-scope="text">
        {{ text | menuTypeFilter }}
      </span>

      <span slot="flagEnable" slot-scope="text,record">
        <a-switch v-model="record.flagEnable" @change="onChangeStatus(record)" />
      </span>

      <!-- <span slot="action" slot-scope="text, record">
        <a v-if="editEnabel" @click="handleEdit(record)">编辑</a>
        <a-divider type="vertical" />
       <a v-if="addEnable" @click="handleAdd(record.id+'')">新增</a>
       <a-divider type="vertical" />
        <a v-if="removeEnable" @click="delById(record.id)">删除</a>
      </span> -->
    </a-table>

    <dept-modal ref="modal" @ok="handleOk"/>
    <linkDirector ref="linkDirector" @onload="handleOk"></linkDirector>
  </a-card>
</template>

<script>
import { Table as T } from 'ant-design-vue'
import { getDeptList, delDept } from '@/api/system'
import DeptModal from './modules/DeptModal.vue'
import linkDirector from './components/link.vue'
import { treeData } from '@/utils/treeutil'
import { checkPermission } from '@/utils/permissions'
export default {
  name: 'TableList',
  components: {
    T,
    DeptModal,
    linkDirector
  },
  data () {
    return {
      // description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',

      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      form: null,
      mdl: {},
      // 高级搜索 展开/禁用
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '部门',
          dataIndex: 'name'
        },
        {
          title: '编号',
          dataIndex: 'code'
        },
        {
          title: '部门主管',
          dataIndex: 'leaderName',
          key: 'leaderName',
          /*width:150,*/
          align:'left',
          ellipsis:true
        },
        {
          title: '状态',
          dataIndex: 'flagEnable',
          scopedSlots: { customRender: 'flagEnable' }
        },
        // {
        //   title: '操作',
        //   width: '150px',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      pagination: false,
      loading: false,
      addEnable: checkPermission('system:dept:add'),
      editEnabel: checkPermission('system:dept:edit'),
      removeEnable: checkPermission('system:dept:remove'),
      linkEnable:checkPermission('system:dept:linkEnable')
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        true: '停用',
        false: '正常'
      }
      return statusMap[status]
    },
    menuTypeFilter (type) {
      const menuMap = {
        'M': '目录',
        'F': '按钮',
        'C': '菜单'
      }
      return menuMap[type]
    }
  },
   computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  created () {
    this.fetch()
  },
  methods: {
    // 隔行换色
        rowClassName(record,index) {
            let className = "light-row"
            if (index % 2 === 1) className = "dark-row"
            return className;
        },
    handleAdd (parentId) {
      this.$refs.modal.add(parentId)
    },
    // handleEdit (record) {
    //   this.$refs.modal.edit(record)
    // },
    handleOk () {
      this.fetch()
    },
    delById (id) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中部门吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          delDept(id).then(res => {
            if (res.code === 200) {
              that.$message.success(res.message)
              that.handleOk()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel () {
          // console.log('Cancel')
        }
      })
    },
    onChangeStatus(row) {
      this.axios.post('/api/base/system/department/status/' + row.id).then(res => {
        if(res.code) {
          this.$message.success(res.message)
          this.handleOk()
        }
      }).catch(err => {
        this.handleOk()
      })
    },
    fetch () {
      this.loading = true
      getDeptList(Object.assign(this.queryParam)).then(res => {
          if (res.code === 200) {
            const {records } = res.body

            // this.tableData = treeData(records)
            let Bloon = records.every((item) => {
              return item.parentId != 0
            })
            if (Bloon) {
              function isChildrenFun(arr) {
                if (arr.length) {
                  for (let i in arr) {
                    if (arr[i].children.length) {
                      isChildrenFun(arr[i].children)
                    } else {
                      delete arr[i].children
                    }
                  }
                }
                return arr
              }
              let newArr = isChildrenFun(records)
              this.data = newArr
            } else {
              this.data = treeData(records)
            }
          }
        this.loading = false
      })
    },
    /**
     * 重置
     */
    reset() {
      this.queryParam = {}
      this.loading = true
      getDeptList(Object.assign(this.queryParam)).then(res => {
        this.data = treeData(res.body.records)
        // this.data = res.body.records
        this.loading = false
      })
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerDepartmentEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.modal.edit(_this.selectedRows[0])
          break
          case 'link':
          _this.$refs.linkDirector.isShow(_this.selectedRows[0])
          break
        case 'del':
           _this.delById(_this.selectedRows[0].id)
          break
      }
    },
  }

}
</script>


<style scoped>
::v-deep .ant-table-tbody > .light-row {
        background: #FFF!important;;
    }
    ::v-deep .ant-table-tbody > .dark-row {
        background: #fafafa!important;
    }
</style>